<template>
  <div id="centreLeft2Chart" style="width: 100%; height: 100%"></div>
</template>

<script>
import echartMixins from "@/utils/resizeMixins";
import { AQIData } from "@/utils/masterdata.js";
export default {
  data() {
    return {
      chart: null,
      blueMax: [],
      yellowAvg: [],
      diff: [],
      line: 0,
      count: "",
      category: [
        "1日",
        "",
        "3日",
        "",
        "5日",
        "",
        "7日",
        "",
        "9日",
        "",
        "11日",
        "",
        "13日",
        "",
        "15日",
        "",
        "17日",
        "",
        "19日",
        "",
        "21日",
        "",
        "23日",
        "",
        "25日",
        "",
        "27日",
        "",
        "29日",
        "",
        "31日",
      ],
      axisLine: {
        lineStyle: {
          color: "#093E83",
        },
      },
      axisLabel: {
        textStyle: {
          color: "white",
        },
      },

      colorList: [],
    };
  },
  mixins: [echartMixins],
  mounted() {
    this.AQIData();
    this.timer = window.setInterval(() => {
      setTimeout(() => {
        this.AQIData();
      }, 0);
    }, 3600000);
  },
  destroyed() {
    window.clearInterval(this.timer);
  },
  created() { },
  methods: {
    fontSize(res) {
      let clientWidth =
        window.innerWidth ||
        document.documentElement.clientWidth ||
        document.body.clientWidth;
      if (!clientWidth) return;
      let fontSize = 100 * (clientWidth / 1920);
      console.log(res * fontSize);
      return res * fontSize;
    },
    AQIData() {
      AQIData().then((res) => {
        this.blueMax = res.body.now;
        this.yellowAvg = res.body.old;
        this.count = res.body.count;
        this.diff = res.body.diff;
        let colorList = [];
        for (let i = 0; i < res.body.diff.length; i++) {
          if (res.body.diff[i] > 0) {
            colorList.push("#D42C2C");
          } else {
            colorList.push("#00AA7C");
          }
        }
        this.colorList = colorList;
        this.$nextTick(() => {
          this.draw();
        });
      });
    },
    draw() {
      // 基于准备好的q，初始化echarts实例
      this.chart = this.$echarts.init(
        document.getElementById("centreLeft2Chart")
      );
      function fontSize(res) {
        let clientWidth =
          window.innerWidth ||
          document.documentElement.clientWidth ||
          document.body.clientWidth;
        if (!clientWidth) return;
        let fontSize = 100 * (clientWidth / 1920);
        return res * fontSize;
      }
      let textStyle = {
        color: "white", // 主标题文字的颜色。
      };
      let category = this.category;
      let option = {
        tooltip: {
          trigger: "axis",
        },
        toolbox: {
          feature: {
            show: false,
          },
        },
        title: {
          text: "当前优良天数：" + this.count,
          textStyle: {
            color: "white",
            fontSize: fontSize(0.2),
          },
          x: "70%",
        },
        legend: {
          x: "5%",
          y: "1%",
          data: ["往年", "当日", "差值"],
          textStyle: textStyle,
        },
        grid: {
          left: "3%",
          top: "10%",
          right: "5%",
          bottom: "0%",
          containLabel: true,
        },

        xAxis: {
          type: "category",
          boundaryGap: true,
          data: category,
          axisLabel: {
            interval: 0,
            formatter: "{value}",
            show: true,
            padding: [0, 0, 20, 0],
            color: "white",
            fontSize: 10,
          },
          splitLine: {
            lineStyle: {
              color: "#00c7ff",
              width: 1,
              type: "solid",
            },
          },
        },
        yAxis: {
          min: -40,
          max: 300,
          splitLine: this.axisLine,
          axisLabel: this.axisLabel,

          axisLine: this.axisLine,
        },

        series: [
          {
            name: "差值",
            type: "bar", //类型设定柱状图
            barWidth: 10, //柱状图宽度
            data: this.diff,
            itemStyle: {
              normal: {
                color: (params) => {
                  return this.colorList[params.dataIndex];
                },
              },
            },
          },
          {
            name: "往年",
            type: "line",
            data: this.yellowAvg,
            label: {
              show: false,
              color: "white",
              formatter: function (data) {
                return data.value;
              },
            },
            itemStyle: {
              normal: {
                color: "#E5971B",
              },
            },
          },

          {
            name: "当日",
            type: "line",

            data: this.blueMax,

            label: {
              show: false,
              color: "white",
              formatter: function (data) {
                return data.value;
              },
            },

            itemStyle: {
              normal: {
                color: "#0090BC",
              },
            },
          },
        ],
      };

      this.chart.setOption(option);
      window.onresize = this.chart.resize;
    },
  },
};
</script>

<style lang="scss">
</style>
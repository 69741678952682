<template>
  <div id="bottomRight">
    <div v-for="item in selectPage" :class="item.is_selected ? ['isselect'] : ['noselect']" :key="item.value"
      @click="selectitem(item.value)">
      {{ item.label }}
    </div>
    <bottomRightChart ref="bottomRightChart" />
  </div>
</template>

<script>
import bottomRightChart from "@/components/echart/bottom/bottomRightChart";
export default {
  data() {
    return {
      type: "pm25",
      selectPage: [
        { label: "pm2.5", value: "pm25", is_selected: true },
        { label: "pm10", value: "pm10", is_selected: false },
        { label: "氮氧化物", value: "NOx", is_selected: false },
        { label: "一氧化氮", value: "NO", is_selected: false },
        { label: "二氧化氮", value: "NO2", is_selected: false },
        { label: "二氧化硫", value: "SO2", is_selected: false },
        { label: "一氧化碳", value: "CO", is_selected: false },
      ],
    };
  },
  components: {
    bottomRightChart,
  },
  mounted() {
    this.$refs.bottomRightChart.getTypeData(this.type);
  },
  methods: {
    selectitem(e) {
      this.selectPage.forEach((element) => {
        if (element.value == e) {
          element.is_selected = true;
        } else {
          element.is_selected = false;
        }
      });
      this.$refs.bottomRightChart.getTypeData(e);
    },
  },
};
</script>

<style lang="scss" scoped>
.isselect {
  height: 30px;
  line-height: 30px;
  background: rgba(17, 99, 150, 0.51);
  border: 1px solid #2aadff;
  text-align: center;
  width: calc((100% / 7));
  float: left;
  border: 1px solid #2aadff;
}

.noselect {
  height: 30px;
  line-height: 30px;
  background: green;
  border: 1px solid wheat;
  text-align: center;
  width: calc((100% / 7));
  float: left;
  border: 1px solid #2effc6;
}

#bottomRight {
  height: 100%;
  padding: 0.2em;
  width: 100%;
}
</style>

<template>
  <div style="height: 100%; width: 100%">
    <div class="thetitle">
      <span>当前预估排名</span>
      <span>20</span>
    </div>
    <dv-scroll-board
      :config="config"
      style="width: 100%; height: 300px; padding: 1em; background: none"
    />
  </div>
</template>

<script>
export default {
  data() {
    return {
      config: {
        header: ["排名", "站点信息", "综合排名", "优良天数", "PM2.5"],
        data: [
          ["1", "站点信息1", "1", "123", "PM2.5"],
          ["2", "站点信息1", "2", "123", "PM2.5"],
          ["3", "站点信息1", "3", "123", "PM2.5"],
          ["4", "站点信息1", "4", "123", "PM2.5"],
          ["1", "站点信息1", "1", "123", "PM2.5"],
          ["2", "站点信息1", "2", "123", "PM2.5"],
          ["3", "站点信息1", "3", "123", "PM2.5"],
          ["4", "站点信息1", "4", "123", "PM2.5"],
          ["1", "站点信息1", "1", "123", "PM2.5"],
          ["2", "站点信息1", "2", "123", "PM2.5"],
          ["3", "站点信息1", "3", "123", "PM2.5"],
          ["4", "站点信息1", "4", "123", "PM2.5"],
        ],
        headerBGC: "none",
        oddRowBGC: "none",
        evenRowBGC: "none",
      },
    };
  },

  methods: {},
};
</script>

<style scoped>
.header {
  background-color: none !important;
}
.thetitle {
  width: calc(100% - 20px);
  height: 50px;
  margin: 5px 0.515px;
  background: #121839;
  font-size: 2.5px;
  padding: 10px;
}
</style>

<template>
  <div style="height: 100%">
    <div style="height: 10%; width: 97%">
      <div class="tianqiclass" style="padding-left: 5%; width: 100%">
        <div class="tianqiclass-2" style="text-algin: left; width: 100%; font-size: 14px">
          除一氧化碳单位为mg/m3米,其余均为ug/m3
        </div>
      </div>
    </div>
    <dv-capsule-chart v-if="jiaonangstatus" :config="config" style="height: 50%; width: 97%" />
    <div style="
        height: 40%;
        width: 90%;
        margin-left: 5%;
        margin-right: 5%;
        border-top: 1px solid #093e83;
      ">
      <div class="tianqiclass">
        <div class="tianqiclass-1">温度（℃）:</div>
        <div class="tianqiclass-2">{{ other.temp }}</div>
      </div>
      <div class="tianqiclass">
        <div class="tianqiclass-1">{{ " " }}湿度（%）:</div>
        <div class="tianqiclass-2">{{ other.humi }}</div>
      </div>

      <div class="tianqiclass">
        <div class="tianqiclass-1">气压（HPA）:</div>
        <div class="tianqiclass-2">{{ other.air }}</div>
      </div>
      <div class="tianqiclass">
        <div class="tianqiclass-1">{{ " " }}风速(M/S):</div>
        <div class="tianqiclass-2">{{ other.windSpeed }}</div>
      </div>
      <div class="tianqiclass">
        <div class="tianqiclass-1">风向（°）:</div>
        <div class="tianqiclass-2">{{ other.windDir }}</div>
      </div>
    </div>
  </div>
</template>

<script>
// getAqiInfoDetail
import { getAqiInfoDetail, getAQIInfoDetailOther } from "@/utils/masterdata.js";
export default {
  data() {
    return {
      jiaonangstatus: false,
      config: {
        data: [],
        showValue: true,
      },
      other: {},
    };
  },
  mounted() {
    this.getAqiInfoDetail();
    this.getAQIInfoDetailOther();
  },
  methods: {
    async getAQIInfoDetailOther() {
      let data = await getAQIInfoDetailOther();
      this.other = data.body;
    },
    async getAqiInfoDetail() {
      let data = await getAqiInfoDetail();
      let wenduarray = [];

      for (let i = 0; i < data.body.list.length; i++) {
        if (i >= 7) {
          wenduarray.push(data.body.list[i]);
        }
      }

      this.config.data = data.body.list;

      this.jiaonangstatus = true;
    },
  },
};
</script>

<style>
.unit-label>div {
  display: none !important;
}

.tianqiclass {
  width: 46%;
  float: left;
  height: 40px;
  line-height: 48px;
  font-size: 14px;
  padding: 1%;
}

.tianqiclass-2 {
  float: left;
  text-align: left;
  width: 30%;
  font-weight: bold;
}

.tianqiclass-1 {
  float: left;
  text-align: left;
  width: 70%;
  font-weight: bold;
}
</style>

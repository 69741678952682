<template>
  <div class="bottomLeft-dom">
    <bottomLeftChart />
  </div>
</template>

<script>
import bottomLeftChart from "@/components/echart/bottom/bottomLeftChart";
export default {
  data() {
    return {};
  },
  components: {
    bottomLeftChart,
  },
  mounted() { },
  methods: {},
};
</script>

<style>
.bottomLeft-dom {
  height: 100%;
  padding: 0.2em;
  width: 100%;
}
</style>
<template>
  <div
    id="bottomRightChart"
    class="height"
    style="width: 100%; margin-top: 30px"
  ></div>
  <!-- <div></div> -->
</template>

<script>
import echartMixins from "@/utils/resizeMixins";
import { getTypeData } from "@/utils/masterdata.js";

export default {
  data() {
    return {
      axisLine: {
        lineStyle: {
          color: "#093E83",
        },
      },
      axisLabel: {
        textStyle: {
          color: "white",
        },
      },
      type: "pm25",
      chart: null,
      blueMax: [],
      wzAvg: [],
      yellowAvg: [],
      diff: [],
      line: 0,
      category: [
        "0时",
        "",
        "2时",
        "",
        "4时",
        "",
        "6时",
        "",
        "8时",
        "",
        "10时",
        "",
        "12时",
        "",
        "14时",
        "",
        "16时",
        "",
        "18时",
        "",
        "20时",
        "",
        "22时",
        "",
      ],
      color: {
        linearGtoB: {
          type: "linear",
          x: 0,
          y: 0,
          x2: 1,
          y2: 0,
          colorStops: [
            {
              offset: 0,
              color: "#43dfa2",
            },
            {
              offset: 1,
              color: "#28f8de",
            },
          ],
        },
        linearBtoG: {
          type: "linear",
          x: 0,
          y: 0,
          x2: 1,
          y2: 0,
          colorStops: [
            {
              offset: 0,
              color: "#1c98e8",
            },
            {
              offset: 1,
              color: "#28f8de",
            },
          ],
        },
        areaBtoG: {
          type: "linear",
          x: 0,
          y: 0,
          x2: 0,
          y2: 1,
          //   背景渐变色
          colorStops: [
            {
              offset: 1,
              color: "rgba(0,0,255,0.1)",
            },
            {
              offset: 0.9,
              color: "rgba(0,51,204,0.2)",
            },
            {
              offset: 0.8,
              color: "rgba(0,153,255,0.3)",
            },
            {
              offset: 0.7,
              color: "rgba(51,153,255,0.4)",
            },
            {
              offset: 0.6,
              color: "rgba(153,204,255,0.5)",
            },
            {
              offset: 0.5,
              color: "rgba(255, 153, 102,0.6)",
            },
            {
              offset: 0.4,
              color: "rgba(255, 102, 102,0.7)",
            },
            {
              offset: 0.3,
              color: "rgba(255, 92, 51,0.8)",
            },
            {
              offset: 0.2,
              color: "rgba(255, 51, 0,0.9)",
            },
            {
              offset: 0,
              color: "rgba(255, 0, 0,1)",
            },
          ],
        },
      },
      colorList: [],
    };
  },
  mixins: [echartMixins],
  mounted() {
    this.timer = window.setInterval(() => {
      setTimeout(() => {
        this.getTypeData();
      }, 0);
    }, 3600000);
  },
  destroyed() {
    window.clearInterval(this.timer);
  },
  created() {},
  methods: {
    getTypeData(type) {
      if (!type) {
        type = this.type;
      }
      getTypeData(type).then((res) => {
        this.blueMax = res.body.blueMax;
        this.wzAvg = res.body.wzAvg;
        this.yellowAvg = res.body.yellowAvg;
        this.diff = res.body.diff;
        this.line = res.body.line;
        this.yz = res.body.yz;
        let colorList = [];
        for (let i = 0; i < res.body.diff.length; i++) {
          if (res.body.diff[i] > 0) {
            colorList.push("#D42C2C");
          } else {
            colorList.push("#00AA7C");
          }
        }
        this.colorList = colorList;
        this.$nextTick(() => {
          this.draw();
        });
      });
    },
    draw() {
      // 基于准备好的dom，初始化echarts实例
      this.chart = this.$echarts.init(
        document.getElementById("bottomRightChart")
      );
      function fontSize(res) {
        let clientWidth =
          window.innerWidth ||
          document.documentElement.clientWidth ||
          document.body.clientWidth;
        if (!clientWidth) return;
        let fontSize = 100 * (clientWidth / 1920);
        return res * fontSize;
      }

      let category = this.category;
      let color = this.color;
      let option = {
        tooltip: {
          trigger: "axis",
        },
        toolbox: {
          feature: {
            show: false,
          },
        },

        legend: {
          show: true,
          itemWidth: 16,
          itemHeight: 8,
          itemGap: 16,
          bottom: 10,
          textStyle: {
            color: "#A3E2F4",
            fontSize: 12,
            fontWeight: 0,
          },
          data: ["实时数据", "旺庄当日数据", "日均线"],
        },
        grid: {
          left: "3%",
          top: "5%",
          right: "5%",
          bottom: "5%",
          containLabel: true,
        },

        xAxis: {
          type: "category",
          boundaryGap: true,
          data: category,
          axisLabel: {
            interval: 0,
            formatter: "{value}",
            show: true,
            padding: [0, 0, 20, 0],
            color: "#00c7ff",
            fontSize: 10,
          },
          //   竖向背景轴颜色
          axisLine: this.axisLine,
        },
        yAxis: {
          type: "value",
          // 背景坐标轴颜色配置
          axisLine: this.axisLine,
          min: this.yz[0],
          max: this.yz[this.yz.length - 1],
          formatter: this.yz,
          //   横向背景轴颜色
          axisLabel: this.axisLabel,
          splitLine: this.axisLine,
        },

        series: [
          {
            name: "实时数据",
            type: "line",
            data: this.blueMax,
            label: {
              show: false,
              color: "white",
              formatter: function (data) {
                return data.value;
              },
            },
            itemStyle: {
              normal: {
                color: color.linearBtoG,
              },
            },
            markLine: {
              symbol: false,
              data: [{ type: "average", yAxis: this.line, name: "平均值" }],
              itemStyle: {
                normal: {
                  color: "#ff9933",
                },
              },
              lineStyle: {
                normal: {
                  opacity: this.line == null ? 0 : 1,
                },
              },
              label: {
                normal: {
                  fontSize: fontSize(0.14),
                  formatter: this.line == null ? "" : "优\n良\n阈\n值",
                },
              },
            },
          },
          {
            name: "旺庄当日数据",
            type: "line",
            data: this.wzAvg,
            label: {
              show: false,
              color: "white",
              formatter: function (data) {
                return data.value;
              },
            },
            itemStyle: {
              normal: {
                color: color.linearBtoGB,
              },
            },
          },
        ],
      };

      this.chart.setOption(option);
    },
  },
};
</script>

<style lang="scss">
.height {
  height: calc(100% - 30px);
}
</style>

<template>
  <div id="index">
    <div class="bg" style="transform: none !important">
      <dv-loading v-if="loading">Loading...</dv-loading>
      <div v-else class="host-body">
        <div style="width: 100%; height: 50px; margin-bottom: 10px">
          <div class="lefttixing">
            <div class="tianqi">
              <!--
                " -->
              <img
                v-if="
                  tianqi.weather == '大雨' ||
                  tianqi.weather == '暴雨' ||
                  tianqi.weather == '小雨' ||
                  tianqi.weather == '雨'
                "
                src="https://img.cdn.xingningjt.com/uploads//1649422542/yu.png"
                alt=""
                style="height: 26px; margin-top: 59%"
              />
              <img
                v-if="tianqi.weather == '晴'"
                src="https://img.cdn.xingningjt.com/uploads//1649422647/qing.png"
                alt=""
                style="height: 26px; margin-top: 59%"
              />
              <img
                v-if="tianqi.weather == '多云'"
                src="https://img.cdn.xingningjt.com/uploads//1649422704/duoyun.png"
                alt=""
                style="height: 26px; margin-top: 50%; margin-bottom: 10%"
              />
            </div>
          </div>
          <div class="center" @dblclick="jumpBackManger">江溪街道数字大屏</div>
          <div class="righttixing">
            <div
              class="tianqi"
              style="margin-left: 60%; line-height: 70px; font-weight: bold"
            >
              {{ dateFormat(date) }}
            </div>
            <el-button
              style="margin-top: 18px; margin-left: 10px"
              round
              circle
              type="primary"
              size="small"
              icon="el-icon-right"
              @click="jumpBackManger"
            ></el-button>
          </div>
        </div>
        <div class="leftthree">
          <div class="titletext">
            <div class="sanjiao"></div>
            <div class="text">实时监测因子状况</div>
          </div>
          <div class="chartsdommin" style="height: 420px">
            <jiaonang />
          </div>
          <div class="titletext">
            <div class="sanjiao"></div>
            <div class="text" style="margin-top: 5px">空气质量等级分布</div>
          </div>
          <div class="chartsdommin" style="height: 420px">
            <doblepie />
          </div>
        </div>
        <div class="sigongge" style="width: 41%">
          <centerLeft1 />
        </div>
        <div class="sigongge" style="margin-left: 0">
          <div class="titletext">
            <div class="sanjiao"></div>
            <div class="text">实时最新30天AQI</div>
          </div>
          <div class="chartsdom">
            <centerRight1 />
          </div>
        </div>
        <div class="sigongge" style="width: 41%">
          <div class="titletext">
            <div class="sanjiao"></div>
            <div class="text">臭氧浓度极限控制曲线图</div>
          </div>
          <div class="chartsdom">
            <bottomLeft />
          </div>
        </div>
        <div class="sigongge" style="margin-left: 0">
          <div class="titletext">
            <div class="sanjiao"></div>
            <div class="text">监测因子实况曲线图</div>
          </div>
          <div class="chartsdom">
            <bottomRight />
          </div>
        </div>
      </div>
    </div>
    <!-- 排名弹窗 -->
    <myDialog />
  </div>
</template>

<script>
import doblepie from "@/views/doblepie/doblepie.vue";
import jiaonang from "@/views/jiaonang/jiaonang.vue";
import centerLeft1 from "./centerLeft1";
import paimingqingkuang from "@/views/paimingqingkuang/paimingqingkuang.vue";
// import centerLeft2 from "./centerLeft2";
import centerRight1 from "./centerRight1";
// import centerRight2 from "./centerRight2";
// import center from "./center";
import bottomLeft from "./bottomLeft";
import bottomRight from "./bottomRight";
import { gettianqi } from "@/utils/masterdata.js";
//引入弹窗组件
import myDialog from "./dialog.vue";
export default {
  data() {
    return {
      loading: true,
      tianqi: {},
      time: "",
      date: new Date(),
    };
  },
  components: {
    paimingqingkuang,
    doblepie,
    jiaonang,
    centerLeft1,
    centerRight1,
    bottomLeft,
    bottomRight,
    myDialog,
  },
  mounted() {
    this.cancelLoading();
    this.gettianqi();

    var _this = this; //声明一个变量指向vue实例this,保证作用域一致
    this.timer = setInterval(function () {
      _this.date = new Date(); //修改数据date
    }, 1000);
  },
  methods: {
    jumpBackManger() {
      this.$router.push("/backmanger/earlywaringmanage");
    },
    async gettianqi() {
      let res = await gettianqi();
      // debugger;
      this.tianqi = res;
    },
    cancelLoading() {
      setTimeout(() => {
        this.loading = false;
      }, 500);
    },
    dateFormat(time) {
      var date = new Date(time);
      var year = date.getFullYear();
      /* 在日期格式中，月份是从0开始的，因此要加0
       * 使用三元表达式在小于10的前面加0，以达到格式统一  如 09:11:05
       * */
      var month =
        date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1;
      var day = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
      var hours =
        date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
      var minutes =
        date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
      var seconds =
        date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
      // 拼接
      return (
        year +
        "-" +
        month +
        "-" +
        day +
        " " +
        hours +
        ":" +
        minutes +
        ":" +
        seconds
      );
    },
  },
};
</script>

<style lang="scss">
.tianqi {
  margin-left: 15px;
  float: left;
  line-height: 70px;
  font-size: 15px;
}

.chartsdommin {
  width: 100%;
  height: 190px;
  border: 1px solid #093e83;
  border-radius: 1em;
}

.leftthree {
  float: left;
  width: 332px;
  margin: 5px 1%;
  margin-right: 0;
  height: 900px;
}

.chartsdom {
  width: 100%;
  height: 420px;
  border: 1px solid #093e83;
  border-radius: 1em;
}

.sigongge {
  height: 450px;
  margin: 5px 1%;
  width: 35%;
  float: left;
}

.text {
  opacity: 1 !important;
  color: white;
  font-size: 10px;
  line-height: 32px;
  width: 100%;
}

.sanjiao {
  border-top: 5px solid transparent;
  border-left: 10px solid #00c3ff;
  border-bottom: 5px solid transparent;
  float: left;
  margin: 10px;
  opacity: 1;
  margin-right: 5px;
}

.titletext {
  border-image: linear-gradient(to right, #000718, #23b7cb) 1 10;
  font-size: 10px;
  height: 30px;
  background: linear-gradient(90deg, #004d65 0%, rgba(90, 158, 179, 0) 100%);
  width: 100%;
  line-height: 30px;
  margin-bottom: 5px;
  font-size: 10px;
}

.lefttixing {
  width: 498px;
  height: 50px;
  background: url("https://img.cdn.xingningjt.com/uploads//1647736770/lefttixing.png");
  background-repeat: no-repeat;
  background-size: 100%;
  float: left;
  padding-left: 4%;
  font-weight: bold;
}

.righttixing {
  width: 498px;
  height: 50px;
  background: url("https://img.cdn.xingningjt.com/uploads//1647736799/righttixing.png");
  background-repeat: no-repeat;
  background-size: 100%;
  float: left;
}

.center {
  height: 50px;
  background: url("https://img.cdn.xingningjt.com/uploads//1647742362/%E7%BB%84%20837%20%E6%8B%B7%E8%B4%9D@2x.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  text-align: center;
  float: left;
  width: 664px;
  font-size: MicrosoftYaHei;
  line-height: 50px;
  font-weight: bold;
  color: #ffffff;
  font-size: 30px;
}

.titlecharts {
  width: 40%;
  padding: 2%;
  font-size: 20px;
  display: block;
  margin: 0 auto;
  text-align: center;
  border: 1px solid #00bfff;
  margin-top: 5px;
  border-top: none;
}

@import "../assets/scss/index.scss";
// .dv-border-box-13 {
//   height: 450px;
// }
// .dv-border-box-13 .border-box-content {
//   height: 470px;
// }
</style>

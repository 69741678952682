<template>
  <div style="height: 100%; width: 100%">
    <div class="pie" style="margin-bottom: 0">
      <div class="moom">当日</div>
      <div id="doblepie" style="height: 80%"></div>
    </div>
    <div class="pie">
      <div class="moom">当月</div>
      <div id="doblepie2" style="height: 80%"></div>
    </div>
  </div>
</template>

<script>
import echartMixins from "@/utils/resizeMixins";
import { AQIRatio, fontSize } from "@/utils/masterdata.js";
export default {
  data() {
    return {
      chart: null,
      chart2: null,
      hoursList: [],
      daysList: [],
      hourRatio: "",

      dayRatio: "",
    };
  },
  mixins: [echartMixins],
  mounted() {
    this.AQIRatio();
  },
  destroyed() { },
  created() { },
  methods: {
    draw() {
      // 基于准备好的q，初始化echarts实例
      this.chart = this.$echarts.init(document.getElementById("doblepie"));

      let option = {
        backgroundColor: "rgba(21, 28, 76, 0.46)",
        tooltip: {
          show: true,
          trigger: "item",
          formatter: (e) => {
            return (
              e.seriesName +
              "<br>" +
              e.name +
              "：" +
              e.marker +
              (e.value * 100).toFixed(0) +
              "%"
            );
          },
        },
        title: [
          {
            text: "小时优良比率：" + this.hourRatio,
            y: "75%",
            x: "33%",
            textStyle: {
              fontSize: this.fontSize(0.12),
              color: "white", // 主标题文字的颜色。
            },
          },
        ],
        series: [
          {
            name: "当日优良小时比率：",
            type: "pie",
            avoidLabelOverlap: true,
            radius: "60%",
            y: "-20%",

            label: {
              normal: {
                show: false,
                position: "inside",
                textStyle: {
                  color: "white",
                  fontSize: this.fontSize(0.12),
                },
                formatter: (e) => {
                  let label = e.name + (e.value * 100).toFixed(0);
                  return label + "%";
                },
              },
            },
            data: this.hoursList,
            itemStyle: {
              normal: {
                color: (params) => {
                  let colorList = ["#00aa7c", "#ffc05c", "#ea302c"];
                  return colorList[params.dataIndex];
                },
              },
            },
          },
        ],
      };

      this.chart.setOption(option);
      window.onresize = this.chart.resize;
    },
    fontSize(e) {
      return fontSize(e);
    },
    draw2() {
      // 基于准备好的q，初始化echarts实例
      this.chart2 = this.$echarts.init(document.getElementById("doblepie2"));

      let option = {
        backgroundColor: "rgba(21, 28, 76, 0.46)",
        tooltip: {
          trigger: "item",
        },
        title: [
          {
            text: "当月优良天数比率:" + this.dayRatio,
            y: "75%",
            x: "33%",
            textStyle: {
              fontSize: this.fontSize(0.12),
              color: "white", // 主标题文字的颜色。
            },
          },
        ],
        series: [
          {
            name: "当月优良比",
            type: "pie",
            avoidLabelOverlap: false,
            radius: "60%",
            y: "-20%",
            label: {
              position: "inside",
              textStyle: {
                color: "white",
                fontSize: this.fontSize(0.12),
              },
              show: false,
              formatter: (e) => {
                let label = e.name + (e.value * 100).toFixed(0);
                return label + "%";
              },
            },
            data: this.daysList,

            itemStyle: {
              normal: {
                color: (params) => {
                  let colorList = ["#00aa7c", "#ffc05c", "#ea302c"];
                  return colorList[params.dataIndex];
                },
              },
            },
          },
        ],
      };

      this.chart2.setOption(option);
      window.onresize = this.chart2.resize;
    },
    async AQIRatio() {
      let data = await AQIRatio();

      this.hourRatio = data.body.hourRatio;
      this.dayRatio = data.body.dayRatio;
      console.log(data.body)
      let hoursList = [
        {
          value: data.body.hours[0],
          name: "优",
          label: { show: false },
          labelLine: { show: false },
        },
        {
          value: data.body.hours[1],
          name: "良",
          label: { show: false },
          labelLine: { show: false },
        },
        {
          value: data.body.hours[2],
          name: "差",
          label: { show: false },
          labelLine: { show: false },
        },
      ];
      let value = hoursList[0].value;
      let index = 0;
      for (let i = 0; i < hoursList.length; i++) {
        if (value < hoursList[i].value) {
          value = hoursList[i].value;
          index = i;
        }
      }
      hoursList[index].label.show = true;
      hoursList[index].labelLine.show = true;

      if (hoursList[index].value == 1) {
        hoursList[index].labelLine = {
          show: true,
          length2: 100,
          length: 5,
          // length2: 240,
        };
      }

      this.hoursList = hoursList;
      let daysList = [
        {
          value: data.body.days[0],
          name: "优",
          label: { show: false },
          labelLine: { show: false },
        },
        {
          value: data.body.days[1],
          name: "良",
          label: { show: false },
          labelLine: { show: false },
        },
        {
          value: data.body.days[2],
          name: "差",
          label: { show: false },
          labelLine: { show: false },
        },
      ];
      let value2 = daysList[0].value;
      let index2 = 0;
      for (let i = 0; i < daysList.length; i++) {
        if (value2 < daysList[i].value) {
          value2 = daysList[i].value;
          index2 = i;
        }
      }
      daysList[index2].label.show = true;
      daysList[index2].labelLine.show = true;
      if (daysList[index2].value == 1) {
        daysList[index2].labelLine = {
          show: true,
          length2: 100,
          length: 5,
        };
      }
      this.daysList = daysList;

      this.draw();
      this.draw2();
    },
  },
};
</script>

<style lang="scss">
.pie {
  height: 45%;
  margin: 3%;

  background: rgba(21, 28, 76, 0.46);
}

#doblepie {
  border-radius: 5em;
}

.moom {
  width: 100%;
  height: 40px;
  font-size: 10px;
  padding-left: 5px;
  line-height: 40px;
  background: rgba(37, 67, 160, 0.14);
}
</style>

<template>
  <div>
    <el-dialog
      title="排行榜"
      :visible.sync="dialogVisible"
      width="30%"
      :before-close="handleClose"
    >
      <el-table :data="tableData" style="width: 100%">
        <el-table-column prop="rank" label="排名" style="width: 50%">
        </el-table-column>
        <el-table-column prop="city" label="城市" style="width: 50%">
        </el-table-column>
      </el-table>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="dialogVisible = false"
          >关 闭</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      dialogVisible: false,
      tableData: [
        {
          rank: "1",
          city: "无锡",
        },
        {
          rank: "2",
          city: "上海",
        },
        {
          rank: "3",
          city: "北京",
        },
        {
          rank: "4",
          city: "苏州",
        },
      ],
    };
  },
  methods: {
    handleClose(done) {
      this.$confirm("确认关闭？")
        .then((_) => {
          done();
        })
        .catch((_) => {});
    },
  },
  mounted() {
    this.$EventBus.$on("sendMsg", (res) => {
      this.dialogVisible = res;
    });
  },
};
</script>
<template>
  <div style="width: 100%; height: 100%">
    <div
      style="height: 30px; width: 50%"
      v-for="(item, index) in selectPage"
      :class="item.is_selected ? ['isselect'] : ['noselect']"
      @click="selectitem(item.value)"
    >
      {{ item.label }}
    </div>
    <div class="height" style="width: 100%; padding-top: 30px">
      <div id="bottomLeftChart" style="width: 100%; height: 100%"></div>
    </div>
  </div>
</template>

<script>
import echartMixins from "@/utils/resizeMixins";
import { getList } from "@/utils/masterdata.js";
export default {
  data() {
    return {
      selectPage: [
        { label: "臭氧", value: 1, is_selected: true },
        { label: "臭氧8", value: 2, is_selected: false },
      ],
      chart: null,
      blueMax: [],
      wzAvg: [],
      diff: [],
      line: 0,
      category: [
        "0时",
        "",
        "2时",
        "",
        "4时",
        "",
        "6时",
        "",
        "8时",
        "",
        "10时",
        "",
        "12时",
        "",
        "14时",
        "",
        "16时",
        "",
        "18时",
        "",
        "20时",
        "",
        "22时",
        "",
      ],
      axisLine: {
        lineStyle: {
          color: "#093E83",
        },
      },
      axisLabel: {
        textStyle: {
          color: "white",
        },
      },
      type: 1,
      colorList: [],
    };
  },
  mixins: [echartMixins],
  mounted() {
    this.getList();
    this.timer = window.setInterval(() => {
      setTimeout(() => {
        this.getList();
      }, 0);
    }, 3600000);
  },
  destroyed() {
    window.clearInterval(this.timer);
  },
  created() {},
  methods: {
    selectitem(e) {
      this.type = e;
      this.selectPage.forEach((element) => {
        if (element.value == e) {
          element.is_selected = true;
        } else {
          element.is_selected = false;
        }
      });
      this.getList();
    },
    getList() {
      getList({ type: this.type }).then((res) => {
        this.blueMax = res.body.blueMax;
        this.wzAvg = res.body.wzAvg;
        this.diff = res.body.diff;
        this.line = res.body.line;
        let colorList = [];

        for (let i = 0; i < res.body.diff.length; i++) {
          if (res.body.diff[i] > 0) {
            colorList.push("#D42C2C");
          } else {
            colorList.push("#00AA7C");
          }
        }
        this.colorList = colorList;
        this.$nextTick(() => {
          this.draw();
        });
      });
    },
    draw() {
      // 基于准备好的q，初始化echarts实例
      this.chart = this.$echarts.init(
        document.getElementById("bottomLeftChart")
      );
      function fontSize(res) {
        let clientWidth =
          window.innerWidth ||
          document.documentElement.clientWidth ||
          document.body.clientWidth;
        if (!clientWidth) return;
        let fontSize = 100 * (clientWidth / 1920);
        return res * fontSize;
      }

      let category = this.category;
      let option = {
        tooltip: {
          trigger: "axis",
        },
        toolbox: {
          feature: {
            show: false,
          },
        },

        legend: {
          show: true,
          itemWidth: 16,
          itemHeight: 8,
          itemGap: 16,
          bottom: 10,

          textStyle: {
            color: "#A3E2F4",
            fontSize: 12,
            fontWeight: 0,
          },
          data: ["旺庄实时", "春城实时", "差值"],
        },
        grid: {
          left: "3%",
          top: "5%",
          right: "5%",
          bottom: "5%",
          containLabel: true,
        },

        xAxis: {
          type: "category",
          boundaryGap: true,
          data: category,
          axisLabel: {
            interval: 0,
            formatter: "{value}",
            show: true,
            padding: [0, 0, 20, 0],
            color: "white",
            fontSize: 10,
          },
          splitLine: {
            lineStyle: {
              color: "#00c7ff",
              width: 1,
              type: "solid",
            },
          },
        },
        yAxis: {
          min: -40,
          max: 300,
          splitLine: this.axisLine,
          axisLabel: this.axisLabel,

          axisLine: this.axisLine,
          formatter: function (value) {
            return [
              -40, -30, -20, -10, 0, 20, 40, 60, 80, 100, 150, 200, 250, 300,
            ];
          },
        },

        series: [
          // {
          //   name: "差值",
          //   type: "bar", //类型设定柱状图
          //   barWidth: 10, //柱状图宽度
          //   show:false,
          //   data: this.diff,
          //
          //   itemStyle: {
          //     normal: {
          //       color: (params) => {
          //         return this.colorList[params.dataIndex];
          //       },
          //     },
          //   },
          // },
          {
            name: "旺庄实时",
            type: "line",
            data: this.wzAvg,
            label: {
              show: true,
              color: "white",
              formatter: function (data) {
                return data.value;
              },
            },
            itemStyle: {
              normal: {
                color: "#E5971B",
              },
            },
          },

          {
            name: "春城实时",
            type: "line",

            data: this.blueMax,
            markLine: {
              data: [
                {
                  type: "average",
                  yAxis: this.line,
                  itemStyle: {
                    normal: {
                      color: "#ff9933",
                    },
                  },
                },
                { type: "average", yAxis: 200 },
              ],

              label: {
                normal: {
                  fontSize: fontSize(0.14),
                  formatter: function (e) {
                    return e.name;
                  }, // 这儿设置安全基线
                },
              },
            },

            label: {
              show: true,
              color: "white",
              formatter: function (data) {
                return data.value;
              },
            },

            itemStyle: {
              normal: {
                color: "#0090BC",
              },
            },
          },
        ],
      };

      this.chart.setOption(option);
      window.onresize = this.chart.resize;
    },
  },
};
</script>

<style lang="scss" scoped>
.isselect {
  height: 30px;
  line-height: 30px;
  background: rgba(17, 99, 150, 0.51);
  border: 1px solid #2aadff;
  text-align: center;
  width: calc((100% / 7));
  float: left;
  border: 1px solid #2aadff;
}

.noselect {
  height: 30px;
  line-height: 30px;
  background: green;
  border: 1px solid wheat;
  text-align: center;
  width: calc((100% / 7));
  float: left;
  border: 1px solid #2effc6;
}

.height {
  height: 100%;
}
</style>

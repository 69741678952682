<template>
  <div id="centreRight1">
    <wangnianduibi />
  </div>
</template>

<script>
import wangnianduibi from "@/components/echart/centerLeft2/wangnianduibi";
export default {
  data() {
    return {};
  },
  components: {
    wangnianduibi,
  },
  mounted() {},
  methods: {},
};
</script>

<style lang="scss">
#centreRight1 {
  height: 100%;
  padding: 0.2em;
  width: 100%;
}
</style>
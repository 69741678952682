<template>
  <div>
    <div class="titletext">
      <div class="sanjiao"></div>
      <div class="text">实时空气质量情况 （ 最后更新时间：{{ nowTime }} ）</div>
    </div>
    <div class="chartsdom">
      <div id="centreLeft1">
        <div class="chartanddate">
          <div id="bilipie" style="width: 40%; height: 100%; float: left"></div>
          <div
            style="
              float: left;
              height: 100%;
              width: 40%;
              padding-top: 10px;
              padding-bottom: 10px;
              padding-left: 50px;
              padding-right: 20px;
            "
          >
            <div class="info" style="padding-top: 40px">
              污染级别：{{ info.body.degree }}
            </div>
            <div class="info" style="padding-top: 10px; padding-bottom: 10px">
              首要污染物：{{ info.body.first }}
            </div>
            <div class="info">浓度值：{{ info.body.mic }}</div>
          </div>
          <div style="float: left; height: 100%; width: 20%; padding-top: 20px">
            <div
              style="
                border: 3px solid #093e83;
                height: 70px;
                width: 90%;
                line-height: 70px;
                text-align: center;
                border-radius: 10% 10% 0 0;
                font-size: 35px;
                font-weight: bold;
              "
            >
              {{ info.body.nowAQI }}
            </div>
            <div class="kongqizhiliang">空气质量</div>
          </div>
        </div>

        <div style="font-size: 20px">情况说明</div>
        <div class="bottomkongqi">
          <div class="sigongge-1 sigongge-click" @click="showDialog">
            <div class="paiming">预估排名</div>
            <div class="ganggang"></div>
            <div class="paiming paimingnumber">{{ info.body.sort }}</div>
          </div>
          <div class="sigongge-1 qingkuang">
            <div class="sangonggetitle">{{ nowMonth.name }}</div>
            <div class="xiaosigongge">
              <div
                class="titleclass"
                :style="{ background: nowMonth.tianshutitlecolor }"
              >
                优良天数
              </div>
              <div
                class="valueclass"
                :style="{ background: nowMonth.tianshuvaluecolor }"
              >
                {{ nowMonth.ratio }}
              </div>
            </div>
            <div class="xiaosigongge">
              <div
                class="titleclass"
                :style="{ background: nowMonth.pm25titlecolor }"
              >
                PM2.5
              </div>
              <div
                class="valueclass"
                :style="{ background: nowMonth.pm25valuecolor }"
              >
                {{ nowMonth.pm25 }}
              </div>
            </div>
            <div class="xiaosigongge">
              <div
                class="titleclass"
                :style="{ background: nowMonth.pm10titlecolor }"
              >
                PM10
              </div>
              <div
                class="valueclass"
                :style="{ background: nowMonth.pm10valuecolor }"
              >
                {{ nowMonth.pm10 }}
              </div>
            </div>
            <div class="xiaosigongge">
              <div
                class="titleclass"
                :style="{ background: nowMonth.no2titlecolor }"
              >
                NO2
              </div>
              <div
                class="valueclass"
                :style="{ background: nowMonth.no2valuecolor }"
              >
                {{ nowMonth.no2 }}
              </div>
            </div>
          </div>
          <div class="sigongge-1 qingkuang">
            <div class="sangonggetitle">{{ lastMonth.name }}</div>
            <div class="xiaosigongge">
              <div class="titleclass">优良天数</div>
              <div class="valueclass">
                {{ lastMonth.ratio }}
              </div>
            </div>
            <div class="xiaosigongge">
              <div class="titleclass">PM2.5</div>
              <div class="valueclass">
                {{ lastMonth.pm25 }}
              </div>
            </div>
            <div class="xiaosigongge">
              <div class="titleclass">PM10</div>
              <div class="valueclass">
                {{ lastMonth.pm10 }}
              </div>
            </div>
            <div class="xiaosigongge">
              <div class="titleclass">NO2</div>
              <div class="valueclass">
                {{ lastMonth.no2 }}
              </div>
            </div>
          </div>
          <div class="sigongge-1 qingkuang">
            <div class="pm25duibi">
              <div class="pm25duibi-title">当年春城PM2.5</div>
              <div class="pm25duibi-lable">
                {{ pm25_1 }}
              </div>
            </div>
            <div class="pm25duibi">
              <div class="pm25duibi-title">当年旺庄PM2.5</div>
              <div class="pm25duibi-lable">
                {{ pm25_2 }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import echartMixins from "@/utils/resizeMixins";
import { AQIInfo } from "@/utils/masterdata.js";
export default {
  data() {
    return {
      info: {
        body: {
          degree: "",
          first: "",
        },
      },
      nowTime: "",
      options: [],
      list: [],
      type: "pm25",
      nowMonth: {},
      lastMonth: {},
      paiming: {},
      pm25_1: "",
      pm25_2: "",
    };
  },
  mixins: [echartMixins],
  mounted() {
    this.AQIInfo();
  },
  methods: {
    showDialog() {
      this.$EventBus.$emit("sendMsg", true);
    },
    selectitem(e) {
      this.info.body.list.forEach((element) => {
        if (element.name == e.name) {
          element.is_selected = true;
          this.paiming = e;
        } else {
          element.is_selected = false;
        }
      });
    },
    makecharts() {
      this.chart = this.$echarts.init(document.getElementById("bilipie"));

      let option = {
        grid: {
          left: "5%",
          top: "5%",
          right: "5%",
          bottom: "5%",
          containLabel: true,
        },

        series: [
          {
            radius: "100%",
            center: ["50%", "80%"],
            type: "gauge",
            startAngle: 180,
            endAngle: 0,
            min: 0,
            max: 300,
            splitNumber: 6,

            pointer: {
              //   icon: "path://M12.8,0.7l12,40.1H0.7L12.8,0.7z",
              length: "90%",
              width: 5,
              offsetCenter: [0, -50],
              itemStyle: {
                color: "auto",
              },
            },
            axisLine: {
              lineStyle: {
                width: 1,
                color: [
                  [1 / 6, "#00AA7C"],
                  [2 / 6, "#FFBD1F"],
                  [3 / 6, "#F46F2A"],
                  [4 / 6, "#C62E17"],
                  [5 / 6, "#7B0C48"],
                  [6 / 6, "#4F023C"],
                ],
              },
            },

            axisTick: {
              length: 10,
              lineStyle: {
                color: "auto",
                width: 2,
              },
            },
            splitLine: {
              length: 20,
              lineStyle: {
                color: "auto",
                width: 5,
              },
            },

            axisLabel: {
              color: "white",
              fontSize: 15,
              distance: -50,
              formatter: function (value) {
                if (value == 0) {
                  return "";
                } else if (value == 50) {
                  return "优";
                } else if (value > 51 && value <= 100) {
                  return "良";
                } else if (value > 101 && value <= 150) {
                  return "轻度";
                } else if (value > 151 && value <= 200) {
                  return "中度";
                } else if (value > 201 && value < 300) {
                  return "重度";
                } else if (value >= 300) {
                  return "严重";
                }
              },
            },

            detail: {
              fontSize: 20,
              offsetCenter: [0, "20%"],
              valueAnimation: true,
              formatter: `${this.info.body.airNo}`,
              color: "auto",
            },
            data: [
              {
                value: this.info.body.nowAQI,
                // name: "Grade Rating",
              },
            ],
          },
        ],
      };

      this.chart.setOption(option);
      //   console.log(option);
    },
    async AQIInfo() {
      let data = await AQIInfo();
      console.log(data);
      for (let i = 0; i < data.body.list.length; i++) {
        if (i == 0) {
          data.body.list[i].is_selected = true;
        } else {
          data.body.list[i].is_selected = false;
        }
      }

      this.nowMonth = data.body.nowMonth;
      this.lastMonth = data.body.lastMonth;
      this.pm25_1 = data.body.list[0].pm25;
      this.pm25_2 = data.body.list[1].pm25;
      this.paiming = data.body.list[0];
      this.nowTime = data.body.nowTime;
      this.info = data;
      this.makecharts();
      if (
        Number(
          data.body.nowMonth.ratio.substr(
            0,
            data.body.nowMonth.ratio.length - 1
          )
        ) <
        Number(
          data.body.lastMonth.ratio.substr(
            0,
            data.body.lastMonth.ratio.length - 1
          )
        )
      ) {
        data.body.nowMonth.tianshutitlecolor = "#EA302C";
        data.body.nowMonth.tianshuvaluecolor = "#482335";
      } else {
        data.body.nowMonth.tianshutitlecolor = "#00AA7C";
        data.body.nowMonth.tianshuvaluecolor = "#26424c";
      }
      if (Number(data.body.nowMonth.pm25) > Number(data.body.lastMonth.pm25)) {
        data.body.nowMonth.pm25titlecolor = "#EA302C";
        data.body.nowMonth.pm25valuecolor = "#482335";
      } else {
        data.body.nowMonth.pm25titlecolor = "#00AA7C";
        data.body.nowMonth.pm25valuecolor = "#26424c";
      }
      if (Number(data.body.nowMonth.pm10) > Number(data.body.lastMonth.pm10)) {
        data.body.nowMonth.pm10titlecolor = "#EA302C";
        data.body.nowMonth.pm10valuecolor = "#482335";
      } else {
        data.body.nowMonth.pm10titlecolor = "#00AA7C";
        data.body.nowMonth.pm10valuecolor = "#26424c";
      }

      if (Number(data.body.nowMonth.no2) > Number(data.body.lastMonth.no2)) {
        data.body.nowMonth.no2titlecolor = "#EA302C";
        data.body.nowMonth.no2valuecolor = "#482335";
      } else {
        data.body.nowMonth.no2titlecolor = "#00AA7C";
        data.body.nowMonth.no2valuecolor = "#26424c";
      }
    },
  },
};
</script>

<style lang="scss">
.pm25duibi {
  height: 50%;
}
.chartsdom {
  width: 100%;
  height: 420px;
  border: 1px solid #093e83;
  border-radius: 1em;
}

.titletext {
  border-image: linear-gradient(to right, #000718, #23b7cb) 1 10;
  font-size: 10px;
  height: 30px;
  background: linear-gradient(90deg, #004d65 0%, rgba(90, 158, 179, 0) 100%);
  width: 100%;
  line-height: 30px;
  margin-bottom: 5px;
  font-size: 10px;
}
.sanjiao {
  border-top: 5px solid transparent;
  border-left: 10px solid #00c3ff;
  border-bottom: 5px solid transparent;
  float: left;
  margin: 10px;
  opacity: 1;
  margin-right: 5px;
}
.pm25duibi-title {
  height: 40px;
  background: #2543a0;
  font-size: 15px;
  text-align: center;
  line-height: 40px;
  border-radius: 10px 10px 0 0;
  color: white;
  font-weight: bold;
}
.text {
  opacity: 1 !important;
  color: white;
  font-size: 10px;
  line-height: 32px;
  width: 100%;
}

.pm25duibi-lable {
  height: 40px;
  width: 100%;
  background: #1c2657;
  padding: 2%;
  font-size: 15px;
  line-height: 40px;
  text-align: center;
  color: white;
  font-weight: bold;
  border-radius: 0 0 10px 10px;
}

.kongqizhiliang {
  border: 3px solid #093e83;
  height: 60px;
  width: 90%;
  text-align: center;
  line-height: 60px;
  border-top: none;
  border-radius: 0 0 2% 2%;
  font-size: 19px;
  font-weight: bold;
}

.isselect {
  height: 30px;
  line-height: 30px;
  background: rgba(17, 99, 150, 0.51);
  border: 1px solid #2aadff;
  text-align: center;
  width: calc((100% / 7));
  float: left;
  border: 1px solid #2aadff;
}

.noselect {
  height: 30px;
  line-height: 30px;
  background: green;
  border: 1px solid wheat;
  text-align: center;
  width: calc((100% / 7));
  float: left;
  border: 1px solid #2effc6;
}

.sangonggetitle {
  height: 23px;
  font-size: 10px;
  line-height: 23px;
}

.oldyear {
  background: #2543a0 !important;
}

.oldvalue {
  background: #1c2657 !important;
}

.titleclass {
  height: 35px;
  width: 100%;
  background: #2543a0;
  font-size: 15px;
  line-height: 35px;
  color: white;
  font-weight: bold;
}

.feiwu {
  height: 35px;
  width: 88px;
  background: #ea302c;
  font-size: 15px;
  line-height: 35px;
  color: white;
  font-weight: bold;
}

.valueclass {
  height: 35px;
  width: 100%;
  background: #1c2657;
  padding: 2%;
  font-size: 15px;
  line-height: 40px;
  color: white;
  font-weight: bold;
}

.xiaosigongge {
  height: 70px;
  width: 48%;
  margin: 1%;
  float: left;
  border-radius: 1em;
}

.paimingnumber {
  font-size: 26px !important;
  font-weight: bold !important;
}

.ganggang {
  background: #5470c6;
  width: 50%;
  height: 2px;
  display: block;
  margin: 0 auto;
}

.paiming {
  height: 90px;
  font-weight: bold;
  text-align: center;
  line-height: 95px;
  font-size: 26px;
}

.sigongge-1 {
  width: 160px;
  height: 190px;
  margin-left: 5px;
  padding: 1%;
  float: left;
  border-radius: 1em;
  background: rgba(21, 28, 76, 0.46);
}

.pingji > span {
  font-size: 30px;
  text-align: center;
  font-weight: bold;
}

.pingjixiangqing {
  display: inline-block;
  height: 6.5px;
  width: 100px;
  padding: 1em;
  padding-left: 0.5em;
  padding-right: 0;
  background: rgba(0, 170, 124, 0.3);
  margin-left: 40px;
  font-size: 14px;
  font-weight: bold;
}

.pingji {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80px;
  height: 80px;
  float: left;
  border: 1px solid green;
  color: white;
  background: green;
  border-radius: 50%;
}

.bottomkongqi {
  height: 190px;
  width: 98%;

  margin: 1%;
}

.info {
  font-weight: bold;
  font-size: 15px;
  font-family: "微软雅黑";
}

.chartanddate {
  height: 40%;
  border-radius: 1em;
  background: #151c4c;
  margin: 1%;
}

.week {
  height: 20px;
  font-size: 15px;
  line-height: 15px;
  font-weight: bold;
}

.wuranwu {
  padding: 5px;
  font-size: 1.01em;
}

.sangongge-1 {
  width: 33%;
  height: 150px;
  float: left;
}

.sangongge {
  width: 100%;
  height: 170px;
  //   background: black;
  float: left;
}

.des {
  //   padding-bottom: 10px;
  clear: both;
  width: 100%;
  height: 30px;
  line-height: 30px;
  margin-bottom: 0.5em;
}

.value {
  text-align: justify;
  text-justify: distribute-all-lines;
  text-align-last: justify;
  font-size: 30px;
  float: left;
  width: 180px;
}

.label {
  font-size: 40px;
  float: left;
}

#centreLeft1 {
  height: 100%;
  padding: 0.2em;
  width: 100%;

  input {
    background: transparent;
    border: #6e9fae 1px solid;
    color: #6e9fae;
  }

  .el-descriptions_table {
    background-color: transparent;
  }
}
</style>
<style lang="less">
.el-descriptions__body {
  background-color: transparent;
  color: white;
}

table {
  border-radius: 15px;
}

.el-descriptions-item__label.is-bordegreen-label {
  background-color: transparent;
  color: white;
}
</style>
<style lang="less" scoped>
.jianyi {
  text-align: center;
  margin-left: 10px;
  columns: #ffffff;
  font-size: 2.5px;
}

.aqititle {
  margin-left: 30px;
  font-size: 32px;
  color: #6e9fae;
  font-weight: bold;
}

/deep/ a-descriptions-item {
  height: 150px;
}

.themoongoodday {
  text-align: center;
  margin-left: 10px;
  font: #4d7887;
  font-size: 20px;
}

.bili {
  margin-left: 10px;
  color: #f63a6f;
  font-size: 15px;
  width: 100%;
  text-align: center;
}

.el-descriptions-row {
  color: #6e9fae;
}
.sigongge-click {
  cursor: pointer;
}
</style>
